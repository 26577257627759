import Router from './Router';
import { useState, useEffect } from 'react';
import { FormContext } from "./contexts/FormContext";
import { SocketContext } from "./contexts/SocketContext";

import './App.css';
import SolarLogo from "./media/logoSolar.png";
import {io} from 'socket.io-client'

function App() {
  const [reconnectionAttempts, setReconnectionAttempts] = useState(0);
  const [loading, setLoading] = useState(true)  
  const [time, setTime] = useState('fetching')  
  const [socket, setSocket] = useState();
  const baseURL = process.env.REACT_APP_BASE_URL;

  const getSocket = () => {
    const baseSocket = io(baseURL, {transports: ['websocket']});
    setSocket(baseSocket);
  }

  useEffect(() => {
    getSocket();
  }, [ ]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on('connect', () => setTime('server connected'));

    socket.on('connect_error', ()=>{
      if (reconnectionAttempts < 10) {
        socket.connect()
        setReconnectionAttempts(reconnectionAttempts + 1);
      } else {
        console.error('Muitas tentativas de reconexão. Desistindo.');
      }
    });

    socket.on('time', (data) => setTime(data));
    socket.on('disconnect',() => setTime('server disconnected'))
    setLoading(false);
 }, [socket]);



  const [formValues, setFormValues] = useState({
    isFormCompleted: false,
    federationState: {
      id: "",
      sigla: "",
    },
    powerDistributionCompany: {
      id: "",
      nome: "",
    },
    consumption: "",
    energySupply: {
      id: "",
      fornecimento: "",
    },
    supplyCategory: "",
    investiment: "",
    tariff: "",
    publicLightTax: 15,
    icms: "",
    simultaneity: "",
    powerBillAnualVariation: "",
    primaryColor: "#d89f14",
    secondaryColor: "#000048",
    logoImage: SolarLogo
  });



  return (
    <div className="App">
      {
        loading 
        
        ? 
          <div> Waiting... </div>
        
        :
          <SocketContext.Provider value={[time, setTime, socket]}>
            <FormContext.Provider value={[formValues, setFormValues]}>
              <Router />
            </FormContext.Provider>
          </SocketContext.Provider>
       } 
    </div>
  );
}

export default App;
